.rbc-button-link{
  width: 100%;
  text-align: center;
}

.sk-calendar {
  .rbc-time-gutter.rbc-time-column {
    width: 35%;

    .rbc-timeslot-group {
      border: 0;

      .rbc-time-slot {
        display: flex;
        align-items: center;

        .rbc-label {
          margin-left: auto;
          margin-right: auto;
          font-size: 1rem;
        }
      }
    }
    .rbc-time-slot:nth-child(2) {
      display: none;
    }
  }

  .rbc-day-slot {
    .rbc-time-slot {
      border: 0;
    }
  }

  .rbc-timeslot-group {
    border: 0;
    &:nth-child(odd) {
      background-color: #c5c4d0;
    }
  }

  &.stripe-secondary {
    .rbc-timeslot-group {
      &:nth-child(odd) {
        background-color: #d2d8de;
      }
    }
  }

  .rbc-today {
    background-color: unset;
  }

  .rbc-time-view,
  .rbc-events-container,
  .rbc-time-content,
  .rbc-day-slot.rbc-time-column,
  .rbc-time-header-content,
  .rbc-time-header,
  .rbc-header,
  .bc-agenda-event-cell {
    border: 0;
  }

  & .rbc-time-content {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .rbc-current-time-indicator,
  .rbc-event-label,
  .rbc-row-content,
  .rbc-allday-cell,
  .rbc-agenda-date-cell,
  .rbc-agenda-time-cell {
    display: none;
  }

  .rbc-agenda-view {
    table.rbc-agenda-table {
      border: none;
      thead {
        display: none;
      }
      tr {
        border: none;
      }
      tbody > tr > td {
        padding: 0;
      }
      tbody > tr > td + td {
        border: none;
      }
    }
  }

  .rbc-agenda-event-cell {
    padding: 0;
  }

  .rbc-time-header-cell-single-day {
    display: inline;
  }

  .rbc-event-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rbc-header {
    font-size: unset;
    text-align: unset;

    a {
      cursor: unset;
    }
  }
}
