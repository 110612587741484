.available-timeslot {
  background-color: "#5bad31";
}

.sk-calendar .rbc-time-gutter.rbc-time-column {
  width: 15% !important;
  min-width: 5rem !important;
  max-width: 6rem !important;
}

.rbc-day-slot {
  cursor: pointer !important;
}

.rbc-btn-group {
  display: flex !important;
}
  
.rbc-btn-group button:nth-child(1) {
  order: 2;
  margin-left: -1px;
}
  
.rbc-btn-group button:nth-child(2) {
  order: 1;
  margin-left: 0px;
  }
  
  .rbc-btn-group button:nth-child(3) {
  order: 3
  }
  
  .rbc-c̵urrent-time-indicator {
    display: block !important;
  }

  .rbc-event-content {
    align-items: flex-start !important;
    display: block !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    width: 55% !important;
  }