.wrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.content {
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease;
}

.content.show {
  height: fit-content;
  transition: height 0.5s ease;
}
.popup_zindex {
  z-index: 100 !important;
}
.cursor-pointer {
  cursor: pointer;
}
/* Add these styles to your CSS file or in a <style> block */

.fade-enter,
.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit {
  opacity: 1;
  transition: opacity 300ms;
}

.scale-enter {
  transform: scale(0.95);
  opacity: 0;
}

.scale-enter-active {
  transform: scale(1);
  opacity: 1;
  transition: transform 300ms, opacity 300ms;
}

.menu_links > li > a {
  cursor: pointer;
}
