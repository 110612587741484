.ReactModal__Content .pdf-canvas canvas {
  margin-left: auto;
  margin-right: auto;
}

.slick-prev:before,
.slick-next:before {
  color: rgb(64, 114, 136) !important;
  font-size: 40px !important;
}
.slick-prev {
  width: 40px !important;
  height: 40px !important;
  left: -20px !important;
}

.slick-next {
  width: 40px !important;
  height: 40px !important;
  right: -20px !important;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-slide > div {
  height: 100%;
}

.sk-shadow-sm {
  box-shadow: 1px 0px 16px -3px rgba(0, 0, 0, 0.78) !important;
}

.sk-shadow-xl {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.6);
}

.h-full-f {
  height: 100% !important;
}

.w-full-f {
  width: 100% !important;
}

.overflow-x-auto::-webkit-scrollbar,
.overflow-y-auto::-webkit-scrollbar,
.overflow-x-scroll::-webkit-scrollbar,
.overflow-y-scroll::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

.overflow-x-auto::-webkit-scrollbar,
.overflow-y-auto::-webkit-scrollbar,
.overflow-x-scroll::-webkit-scrollbar,
.overflow-y-scroll::-webkit-scrollbar {
  display: block;
}

.overflow-x-auto::-webkit-scrollbar-track,
.overflow-y-auto::-webkit-scrollbar-track,
.overflow-x-scroll::-webkit-track,
.overflow-y-scroll::-webkit-track {
  background-color: #d9d9d9 !important;
  border-radius: 2px !important;
}

.overflow-x-auto::-webkit-scrollbar-thumb,
.overflow-y-auto::-webkit-scrollbar-thumb,
.overflow-x-scroll::-webkit-scrollbar-thumb,
.overflow-y-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background-color: #666566 !important;
}

/* Popup Buttons */
.swal-button,
.swal2-cofirm,
.swal2-styled.swal2-confirm {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #407288 !important;
  font-size: 12px;
  border: 1px solid #407288 !important;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

swal-button:hover {
  background-color: #407288;
  font-size: 12px;
  border: 1px solid #407288;
}

/* Edit Course Button */
.editCourseButtonStatic {
  position: static !important;
}

.react-calendar__tile--now {
  background: rgba(250, 0, 94, 0.7) !important;
  color: white !important;
}

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff !important;
}

.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
}

.darker_shadow {
  box-shadow: 5px 10px 15px 5px rgb(0 0 0 / 15%),
    0 10px 8px -1px rgb(0 0 0 / 25%);
}
.menu_shadow {
  box-shadow: 0 8px 20px 0 rgb(0 0 0 / 50%), 0 20px 20px 0 rgb(0 0 0 / 40%);
}
.card_shadow {
  box-shadow: 5px 5px 15px 5px rgb(0 0 0 / 15%),
    0 10px 8px -1px rgb(0 0 0 / 25%);
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

h1 {
  font-size: 2em;
  /* margin: 0.67em 0;  */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-size: revert !important; */
  font-weight: revert !important;
}

h1 {
  font-size: 1.25rem !important;
}

h2 {
  font-size: 1.125rem !important;
}

h3 {
  font-size: 1rem !important;
}

h4 {
  font-size: 0.875rem !important;
}

h5 {
  font-size: 0.75rem !important;
}

h6 {
  font-size: 0.65rem !important;
}
