.semi-circle {
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  height: 2rem;
  width: 4rem;
  border-radius: 0 0 3rem 3rem;
}

@media screen and (min-width: 640px) {
  .semi-circle {
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    height: 2.5rem;
    width: 5rem;
    border-radius: 0 0 3.75rem 3.75rem;
  }
}

@media screen and (min-width: 1024px) {
  .semi-circle {
    height: 5rem;
    width: 2.5rem;
    border-radius: 0 3.75rem 3.75rem 0;
  }
}

.course-setup-main-section {
  height: calc(100% - 3rem);
}

.footer {
  height: 3rem;
}

.react-calendar__navigation {
  display: flex;
}