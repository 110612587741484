body {
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Poppins, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.h5p-hub-button.h5p-hub-button-primary {
    background-color: '#407288' !important; 
}

.h5p-hub-button {
    background-color: '#407288' !important;
}

.h5p-hub-button-primary {
    background-color: '#407288' !important;
}

.h5p-hub  {
    background-color: '#407288' !important;
}

.h5p-hub-navbar {
    display: none !important;
}