.NavBar-width {
  width: 100%;
}
.Contentview-width {
  width: 91.666667%;
}

.NavBar-height {
  height: 5rem;
}
.Contentview-height {
  height: calc(100% - 4rem) !important;
}

.Contentview-title-height {
  height: 4rem;
}

.Contentview-content-height {
  height: calc(100% - 4rem) !important;
}

@media screen and (min-width: 640px) {
  .NavBar-height {
    height: 6rem;
  }
  .Contentview-height {
    height: calc(100% - 5rem) !important;
  }
}

@media screen and (min-width: 1024px) {
  .NavBar-width {
    width: 6rem;
  }
  .Contentview-width {
    width: calc(100% - 6rem);
  }

  .NavBar-height {
    height: 100%;
  }
  .Contentview-height {
    height: 100% !important;
  }
}

.Toastify__toast {
  cursor: default !important;
}

.multiline-elipsis-2 {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.gradient-background {
  background: rgb(42, 98, 122);
  background: -moz-linear-gradient(
    90deg,
    rgba(42, 98, 122, 1) 0%,
    rgba(12, 88, 116, 1) 25%,
    rgba(0, 96, 139, 1) 50%,
    rgba(0, 73, 114, 1) 75%,
    rgba(0, 50, 89, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(42, 98, 122, 1) 0%,
    rgba(12, 88, 116, 1) 25%,
    rgba(0, 96, 139, 1) 50%,
    rgba(0, 73, 114, 1) 75%,
    rgba(0, 50, 89, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(42, 98, 122, 1) 0%,
    rgba(12, 88, 116, 1) 25%,
    rgba(0, 96, 139, 1) 50%,
    rgba(0, 73, 114, 1) 75%,
    rgba(0, 50, 89, 1) 100%
  );
}

.logout-animation {
  position: relative;
  animation-name: example;
  animation-duration: 300ms;
}

@keyframes example {
  0%   { top:100px;}
  100% { top:0px;}
}

/* @supports (-webkit-touch-callout: none) { */
/* CSS specific to iOS devices */
/* .flex {
    display: "-webkit-flex" !important;
  }
} */

/* .overflow-x-auto::-webkit-scrollbar, .overflow-y-auto::-webkit-scrollbar, .overflow-x-scroll::-webkit-scrollbar, .overflow-y-auto::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

.overflow-x-auto::-webkit-scrollbar, .overflow-y-auto::-webkit-scrollbar, .overflow-x-scroll::-webkit-scrollbar, .overflow-y-scroll::-webkit-scrollbar {
  display: block;
}

.overflow-x-auto::-webkit-scrollbar-track, .overflow-y-auto::-webkit-scrollbar-track, .overflow-x-scroll::-webkit-track, .overflow-y-scroll::-webkit-track {
  background-color: #F7F7F7 !important;
  border-radius: 2px !important;
}

.overflow-x-auto::-webkit-scrollbar-thumb, .overflow-y-auto::-webkit-scrollbar-thumb, .overflow-x-scroll::-webkit-scrollbar-thumb, .overflow-y-scroll::-webkit-scrollbar-thumb {
  border-radius: 6px !important;
  background-color: #CDCCCC !important; 
} */
