.ring {
  position: fixed;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  border: 2px solid rgba(156, 57, 103, 1);
  border-radius: 100%;
  transform: translate(-50%, -50%);
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  will-change: width, height, transform, border;
  z-index: 999;
  pointer-events: none;
  display: none;
}

.dot {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #9a3b65;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 999;
  pointer-events: none;
  display: none;
}

@media only screen and (min-width: 750px) {
  /* Change max-width to min-width and adjust the value */
  .dot {
    display: block; /* Add this line */
  }
  .ring {
    display: block; /* Add this line */
  }
}
