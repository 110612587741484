.ambassador-list {
    color: black;
    padding: 20px;
}

.ambassador {
    margin-bottom: 10px;
}

.ambassador h2 {
    margin-bottom: 5px;
}

.ambassador-ref-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.ambassador-ref {
    margin-bottom: 5px;
}

.ambassador.expanded h2 {
    color: blue;
}

.ambassador-ref-list {
    display: none;
}

.ambassador.expanded .ambassador-ref-list {
    display: block;
}


table {
    width: 100%;
    border-collapse: collapse;

}

th,
td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

/* Alternate row colors */
tr:nth-child(even) {
    background-color: #f2f2f2;
}