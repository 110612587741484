* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  overflow-y: scroll;
  margin-bottom: 2rem;
}

.my-masonry-grid_column {
  background-clip: padding-box;
  width: "80%";
}

.mobileVideoBanner {
  display: block;
}

.newsHeight {
  height: 43rem;
}

@media (min-width: 768px) {
  .mobileVideoBanner {
    display: none;
  }

  .newsHeight {
    height: 37rem;
  }
}

.aspectratio {
  position: relative;
  height: 0;
  width: 100%;
}

.aspectratio[data-ratio="1:1"] {
  padding-top: 100%;
}

.aspectratio[data-ratio="16:9"] {
  padding-top: 56.25%;
}

.aspectratio[data-ratio="4:3"] {
  padding-top: 75%;
}

.aspectratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card {
  position: relative;
  background-color: #fff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.3) !important;
  border-radius: 25px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.react-card-flip {
  width: 100%;
  height: 100%;
}

.cardContent {
  width: 100%;
  height: 100%;
}

.textCardStyle {
  line-height: 1.4rem;
  padding-top: 1.25rem;
  font-size: 1.22rem;
}

.txtBr {
  display: block;
  margin-top: 2rem;
}

.textCardStyleBold {
  font-weight: bold;
}

.textCardNormal {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgb(117 117 117);
}

.textCardMedium {
  font-size: 1rem;
}

.textCardLarge {
  font-size: 15px;
  line-height: 1.5rem;
}

.slick-arrow {
  z-index: 2;
}

@media screen and (min-width: 1700px) {
  .textCardNormal {
    font-size: 14px;
    line-height: 1.25rem;
  }

  .textCardMedium {
    font-size: 16px;
    line-height: 1.5rem;
  }

  .textCardLarge {
    font-size: 17px;
    line-height: 1.25rem;
  }
}

.react-player__play-icon {
  border-color: transparent transparent transparent black !important;
}

.react-player__shadow {
  height: 80px !important;
  width: 80px !important;
  background: none !important;
  margin-bottom: 5px;
}

.react-player__play-icon {
  border-width: 24px 0px 24px 40px !important;
}

.each-slide > div {
  height: 70vh;
}

@media only screen and (max-width: 1024px) {
  .each-slide > div {
    height: auto;
  }
}

.slideShowImg {
  height: 100%;
}

.indicators {
  margin-top: 30px !important;
}

.indicators > li:not(:last-child) {
  margin-right: 10px !important;
}

.sideInfo_texts > p {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

@media only screen and (max-width: 1024px) {
  .sideInfo_texts > p {
    display: none;
  }
}

.forWhoCardImage {
  transition: filter 0.3s ease-in-out, transform 0.4s ease-in-out;
  -webkit-transition: filter 0.3s ease-in-out, transform 0.4s ease-in-out;
  filter: brightness(80%);
  background-color: black;
  position: relative;
}

.cardCenterText {
  opacity: 1;
  transition: visibility 0.4s ease-in-out, opacity 0.3s ease-in-out;
  -webkit-transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

@media only screen and (max-width: 1024px) {
  .cardCenterText {
    display: none;
  }

  .forWhoCardImage {
    filter: brightness(100%);
  }
}

.forWhoCardImage:hover {
  transform: scale(1.1);
  filter: brightness(100%);
}

.react-player__preview {
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

@media screen and (max-width: 768px) {
  .react-player__preview {
    background-size: cover !important;
  }
}

.react-player__preview {
  background-size: contain !important;
}

.seven-line-elipsis {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}

.blogDetail ul {
  padding: revert;
  margin: revert;
  list-style: revert;
}

.blogDetail ol {
  padding: revert;
  margin: revert;
  list-style: revert;
}
