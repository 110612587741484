.text-loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-loader h1 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;

}