.Title-height {
  height: 4rem;
}
.Content-height {
  height: calc(100% - 12rem);
}

@media screen and (min-width: 1024px) {
  .Title-height {
    height: 5rem;
  }
  .Content-height {
    height: calc(100% - 12rem);
  }
}

.social-icon {
  fill: lightgrey;
  height: 30px;
  width: 30px;
}

.social-icon:hover {
  fill: white;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
}

.blog-icon {
  filter: grayscale(10);
}

.blog-icon:hover {
  filter: grayscale(10);
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
}

.glow {
  animation: glowing 5000ms infinite;
}
@keyframes glowing {
  0% {
    box-shadow: 0 0 -10px #407288;
  }
  40% {
    box-shadow: 0 0 20px #407288;
  }
  60% {
    box-shadow: 0 0 20px #407288;
  }
  100% {
    box-shadow: 0 0 -10px #407288;
  }
}
@media only screen and (min-width: 768px) {
  .menuBtn {
    top: 55%;
  }
}
@media only screen and (min-width: 600px) {
  .menuBtn {
    top: 47%;
  }
}

@media only screen and (max-width: 640px) {
  .info_img {
    display: none;
  }
}

.gradient-background {
  background: rgb(42, 98, 122);
  background: -moz-linear-gradient(
    90deg,
    rgba(42, 98, 122, 1) 0%,
    rgba(12, 88, 116, 1) 25%,
    rgba(0, 96, 139, 1) 50%,
    rgba(0, 73, 114, 1) 75%,
    rgba(0, 50, 89, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(42, 98, 122, 1) 0%,
    rgba(12, 88, 116, 1) 25%,
    rgba(0, 96, 139, 1) 50%,
    rgba(0, 73, 114, 1) 75%,
    rgba(0, 50, 89, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(42, 98, 122, 1) 0%,
    rgba(12, 88, 116, 1) 25%,
    rgba(0, 96, 139, 1) 50%,
    rgba(0, 73, 114, 1) 75%,
    rgba(0, 50, 89, 1) 100%
  );
}

.menu-items {
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms ease-in-out;
}

.menu-items li::before {
  content: "•";
  color: #000;
  margin-right: 10px;
}
.menu-items.open {
  max-height: 300px; /*Adjust the max-height as per the number of list items*/
}

.personaBtn {
  transition: all 0.3s ease-in-out;
}

.personaBtn[data-state="hide"] {
  animation-delay: 0.3s;
}

@keyframes showHide {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.personaBtn[data-state="show"] {
  animation: showHide 0.3s ease-in-out;
}
