.multiline-elipsis {
    width:100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.advanceToolImage {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    /* filter: brightness(80%); */
    /* position: relative; */
  }
  

.advanceToolImage:hover {
    transform: scale(1.1);
    /* filter: brightness(100%); */
  }