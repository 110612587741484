
  #progressInput {
    margin: 20px auto;
    width: 30%;
  }
  
  .circle-background,
  .circle-progress {
    fill: none;
  }
  
  .circle-background {
    stroke: #ddd;
  }
  
  .circle-progress {
    stroke: #407288;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  
  .circle-text {
    font-size: 1.5em;
    font-weight: bold;
    fill: #407288;
  }