.faq-footer-height {
  height: 4rem;
}
.faq-content-height {
  height: 100%;
}
@media (min-width: 1024px) {
  .faq-footer-height {
    height: 4.5rem;
  }

  .faq-content-height {
    height: calc(100% - 4.5rem);
  }
}

.player-height {
  height: 54vh;
}

@media (min-width: 2048px) {
  .faq-footer-height {
    height: 5.5rem;
  }
  .faq-content-height {
    height: calc(100% - 5.5rem);
  }
  .player-height {
    height: 54vh !important;
  }
}



.player-height-inside {
  height: 36vh;
}

@media (min-width: 2048px) {
  .faq-footer-height {
    height: 5.5rem;
  }
  .faq-content-height {
    height: calc(100% - 5.5rem);
  }
  .player-height-inside {
    height: 36vh !important;
  }
}