.persona .indicator {
  position: relative;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  margin: 0;
  animation: indicator-animation-in 0.5s ease;
}

.persona .indicator:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 3px;
  background-color: #ea1661;
  transition: width 0.5s ease;
}

.persona .indicator.active:before {
  width: 100%;
}
.persona .indicators {
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  margin-top: 0 !important;
  margin-bottom: 2rem;
}
.persona .indicator.active {
}

.persona .react-slideshow-container {
  position: relative;
}

.persona .each-slide-effect {
  height: 50rem;
}

@media only screen and (max-width: 1026px) {
  .persona .each-slide-effect {
    height: fit-content;
    margin-top: 8rem;
  }
}
