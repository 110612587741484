    .article {
      min-height: 12rem;
      /* max-height: 24rem; */
    }
  
    .image-container {

    }
  
@media screen and (min-width: 768px) {
  .article {
    max-height: 18rem;
  }

  .image-container {
       max-width: 22rem; 
}

}
