/* @use postcss-nested; */
:root {
  --send-bg: #0B93F6;
  --send-local-bg: #cad2d7;
  --send-local-color: rgb(16, 16, 16);
  --send-color: white;
  --receive-bg: #E5E5EA;
  --receive-text: black;
  --page-background: white;
}

/* body {
	font-family: "Helvetica Neue", Helvetica, sans-serif;
	font-size: 20px;
	font-weight: normal;
  max-width: 450px;
	margin: 50px auto;
  display: flex;
  flex-direction: column;
  background-color: var(--page-background);
} */




.send {
	color: var(--send-color); 
	background: var(--send-bg);
	align-self: flex-end;
    border-radius: 15px;
    padding: 5px;
}	

.send-local {
  color: var(--send-local-color); 
	background: var(--send-local-bg);
	align-self: flex-end;
    border-radius: 15px;
    padding: 5px;
}
	/* .send::before {
		right: -7px;
    width: 20px;
    background-color: var(--send-bg);
		border-bottom-left-radius: 16px 14px;
	}

	.send::after {
		right: -26px;
    width: 26px;
    background-color: var(--page-background);
		border-bottom-left-radius: 10px;
	}  */

.receive {
	background: var(--receive-bg);
	color: black;
  align-self: flex-start;
  border-radius: 15px;
  padding: 5px;

}

.receive p, .send p, .send-local p {
    /* max-width: 255px; */
    word-wrap: break-word;
    margin-bottom: 6px;
    line-height: 18px;
    position: relative;
      padding: 10px 10px;
    /* border-radius: 25px; */
  }
    p::before, p::after {
      content: "";
          position: absolute;
      bottom: 0;
      height: 25px;
    } 
  

    .chat-img-placeholder {
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('../../../assets/images/image-icon.svg');
  }