@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* Part One */
.image-container {
  perspective: 100px;
  position: relative;
}

.image-3d-effect {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.2s;
}

.image-3d-effect:hover {
  transform: scale3d(1.05, 1.05, 1);
}
/* End of Part One */

/* Menu Links */
.menu_link {
  position: relative;
  text-decoration: none;
  transition: transform all 0.5s ease-in;
  cursor: pointer;
}

.menu_link::before {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.menu_link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: -10%;
  background-color: #d65142;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.5s;
}

.menu_link:hover::after {
  transform-origin: bottom left;
  transform: scaleX(1);
}
.menu_link:hover {
  color: #d65142;
}

.svg_logo:hover path {
  fill: #61dafb;
}
/* End of Menu Links */

/* Carousel Styling */
.slick-prev::before,
.slick-next::before {
  color: rgb(32, 32, 32);
  width: 30px;
  height: 30px;
  font-size: 25px;
}

.carousel_parent {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.mobile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; /* This allows clicks to pass through the overlay to the slider */
}

.mobile-overlay img {
  /* Adjust the width and height as needed */
  width: 600px;
  height: 300px;
  height: auto;
}
.activeSlide {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}
/* End Of Carousel Styling */
.icon-small {
  width: 25px;
  height: 25px;
}
.card {
  height: 480px; /* Adjust based on your needs */
  overflow-y: auto;
}

/* Part Four Icons Hover Animation*/
.main_item:hover .svg-icon {
  color: #9a3b65;
}
.svg-icon {
  transition: all 0.5s ease-out;
  color: #edc9c6;
}

.active {
  transition: font-weight 0.3s ease;
  font-weight: bolder;
  color: #9a3b65;
}

/* Part Ten Hover Animation */
.partTen_box:hover .svg-icon {
  color: #9a3b65;
}
.svg-icon {
  transition: all 0.5s ease-out;
  color: #edc9c6;
}

/* .circle {
  position: fixed;
  z-index: 100;
  display: flex;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 10px;
  width: 10px;
  background-color: #d44343;
  border-radius: 200px;
  pointer-events: none;
  color: blue;
  text-align: center;
  font-size: 16px;
}

.cursorText {
  flex: auto;
  font-size: inherit;
  pointer-events: none;
  margin: auto;
} */

.new-landing-layout {
  cursor: none;
  }
    
