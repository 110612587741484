
.aspectratio {
  position: relative;
  height: 0;
  width: 100%;
}

.aspectratio[data-ratio="1:1"] {
  padding-top: 100%;
}

.aspectratio[data-ratio="16:9"] {
  padding-top: 56.25%;
}

.aspectratio[data-ratio="16:10"] {
  padding-top: 62.5%;
}

.aspectratio[data-ratio="4:3"] {
  padding-top: 75%;
}

.aspectratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
