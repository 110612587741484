/* .container {
  width: 95%;
} */

.tableHead {
  background: rgb(250, 0, 94);
}

.tedFilterIcon {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  fill: white;
}

.parentCard {
  height: 24rem;
  width: 20rem;
}

.slick-slide > div {
  display: flex;
  justify-content: center;
}

.slick-slider {
  padding: 0 20px;
}

.slick-prev:before,
.slick-next:before {
  color: white !important;
}

.slick-dots {
  bottom: -40px;
}

.slick-track {
  overflow: hidden;
}

.tutorCard {
  /* 
  box-shadow:1px 1px 10px 0px rgba(0,0,0,0.6) ; */

  border-radius: 15px;
  box-shadow: none;

  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.tutorCard_circle {
  left: 50%;
  top: 35%;
  transform: translate(-50%, -35%);
  background-color: black;
  scale: 0.95;

  height: 6.2rem;
  width: 6.2rem;
  z-index: 1000;

  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.cardText {
  opacity: 0.9;
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

.bgImage {
  opacity: 0.75;
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  background-size: cover;
  background-position: center center;
}

.roundImg {
  background-color: white;
  opacity: 0.85;
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

.bgImage:hover {
  opacity: 1;
}

.parentCard:hover .bgImage,
.bgBottomContent {
  opacity: 1;
}

.tutorCard:hover .bgImage,
.bgBottomContent {
  opacity: 1;
}

.bgBottomContent {
  opacity: 0.8;
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

/* .parentCard:hover .roundImg {
  opacity: 1;
  scale: 1;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
} */
.tutorCard:hover .cardText {
  opacity: 1;
}

.tutorCard:hover .roundImg {
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.tutorCard:hover .tutorCard_circle {
  opacity: 1;
  scale: 1;
}

.tutorCard:hover {
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.6);
}

@media screen and (min-width: 2000px) {
  .parentCard {
    height: 26rem;
    width: 22rem;
  }
}

.courseNameWrap {
  max-width: 16rem;
  overflow: hidden;
  text-align: center;
  white-space: wrap;
  text-overflow: ellipsis;
  display: inline-block;
}

.card_article {
  /* limit the width of the article container */
  width: 250px;
  /* display the contents in a column */
  display: flex;
  flex-direction: column;
  align-items: center;
  background: hsl(0, 0%, 100%);
  line-height: 2;
  border-radius: 10px;
  margin: 0.5rem;
  /* transition for the transform property, updated in the script */
  transition: transform 0.2s ease-out;
  background: radial-gradient(
    circle at 50% 0px,
    transparent 75px,
    rgb(255, 255, 255) 70px
  );
  cursor: pointer;
}
.card_article figure {
  /* limit the width and height of the figure to show the image in a circle */
  width: 120px;
  height: 120px;
  border-radius: 50%;
  /* specify negative margin matching half the height of the element */
  margin-top: -60px;
  /* position relative for the pseudo element */
  position: relative;
  box-shadow: 0px 3px 5px 5px rgba(63, 63, 63, 0.4);
}
.card_article figure:before {
  /* add a border around the figure matching the color of the background, faking the clip */
  content: "";
  border-radius: inherit;
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(
    100% - 2rem
  ); /* subtracting the padding from the width and height */
  height: calc(100% - 2rem);
  transform: translate(-50%, -50%);
  border: 1rem solid transparent; /* set the border color to transparent */
  box-shadow: 0 1px hsla(0, 0%, 0%, 0.1);
}
.card_article figure img {
  /* stretch the image to cover the size of the wrapping container */
  border-radius: inherit;
  width: 100%;
  height: 100%;
  /* object fit to maintain the aspect ratio and fit the width/height */
  object-fit: cover;
  border: 1px solid lightgray;
}

.card_article div {
  /* center the text in the div container */
  text-align: center;
  margin: 2rem;
}
.card_article div p {
  color: hsl(250, 5%, 45%);
  font-weight: 400;
  font-size: 14px;
  margin: 1rem 0 3rem;
  /* position relative for the pseudo element */
  position: relative;
  z-index: 5;
}
.card_article div p:before {
  /* with SVG elements include two icons for the quote */
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  z-index: -5;
  opacity: 0.05;
  /* position the icons at either end of the paragraph, rotate the second to have a mirrorer image */
  /* background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" width="70" height="70"><rect x="0" y="40" width="30" height="30"></rect><path d="M 0 40 q 0 -40 30 -40 v 15 q -15 0 -15 25"></path><rect x="40" y="40" width="30" height="30"></rect><path d="M 40 40 q 0 -40 30 -40 v 15 q -15 0 -15 25"></path></svg>'),
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" width="70" height="70" transform="rotate(180)"><rect x="0" y="40" width="30" height="30"></rect><path d="M 0 40 q 0 -40 30 -40 v 15 q -15 0 -15 25"></path><rect x="40" y="40" width="30" height="30"></rect><path d="M 40 40 q 0 -40 30 -40 v 15 q -15 0 -15 25"></path></svg>');
  background-position: 20% 20%, 80% 80%; */
  background-repeat: no-repeat;
}
.card_article div h1 {
  /* considerably reduce the size of the heading */
  color: hsl(260, 5%, 55%);
  font-family: "Lato", sans-serif;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 5ch; /* This will limit to 5 characters, but it's not exact. */
}
