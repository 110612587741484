*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  /* gutter size offset */
  /* margin-left: -30px;  */
  width: 100%;
  overflow-y: scroll;
  /* padding-bottom: 2rem; */
  /* margin-bottom: 2rem; */
}
.my-masonry-grid_column {
  /* padding-left: 30px;  */
  padding: 10px;
  background-clip: padding-box;
  width: "100%"
}

.truncat-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9; /* number of lines to show */
  -webkit-box-orient: vertical;
}
