.predefined {
  position: sticky;
  bottom: 0px;
  padding-top: 10px;
}

.predefineList {
  float: right;
  margin-left: 10px;
  border: 2px solid #7abbf2;
  padding: 5px;
  border-radius: 10px;
  color: #56a4e9;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  font-size: 12px;
}

.predefineList:hover {
  color: white;
  background-color: #56a4e9;
}

.cs-typing-indicator {
  width: fit-content;
}

.cs-message {
  margin: 1rem auto !important;
}

.clearBtn {
  border: 2px solid red;
  background-color: red;
  color: #fff;
}

.clearBtn:hover {
  color: red;
  background-color: white;
}

.selectRole {
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  color: #56a4e9;
  margin-top: 2rem;
}

.eachRole {
  padding: 20px;
  margin-top: 20px;
  border-radius: 15px;
  border: 2px solid #7abbf2;
  width: 15rem;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.eachRole:hover {
  color: white;
  background-color: #56a4e9;
}

.icon_CHAT {
  width: 50px;
  height: 50px;
}

.cs-message__avatar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cs-avatar>img {
  object-fit: cover;
}

.slider {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.slider:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: 0.4s;
}

.slider:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 26px;
  height: 26px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
}

input[type="checkbox"]:checked+.slider:before {
  background-color: #2196f3;
}

input[type="checkbox"]:checked+.slider:after {
  transform: translateX(26px);
}

.sliderbox {
  display: none;
}

.sliderTitle {
  color: #fff;
  padding: 5px;
  display: block;
  float: left;
  font-weight: 700;
}

.listeningIndicator {
  color: #fff;
  padding: 5px;
  display: block;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background-color: #3498db;
  padding: 20px;
  border-radius: 40px;
  text-align: center;
  z-index: 100;

  box-shadow: 0 8px 20px 0 rgb(0 0 0 / 50%), 0 20px 20px 0 rgb(0 0 0 / 40%);

}

.loading-spinner {
  margin-bottom: 10px;
  /* Add your spinner styles here */
}

.loading-text {
  font-size: 16px;
  font-weight: bold;
  animation: blink 1s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.modal-content p {
  margin-bottom: 20px;
}

.modal-content button {
  background-color: #e74c3c;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  margin-top: 10px;
}

.modal-content button:hover {
  background-color: #c0392b;
}