.faq-footer-height {
  height: 4rem;
}
.faq-content-height {
  height: 100%;
}
@media (min-width: 1024px) {
  .faq-footer-height {
    height: 4.5rem;
  }

  .faq-content-height {
    height: calc(100% - 4.5rem);
  }
}

.touch-scroll {
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch;
}

.player-height {
  height: 54vh;
}

.slick-track {
  min-width: 100% !important;
}

.react-card-back {
  -webkit-transform-style: flat !important;
  transform-style: flat !important;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  perspective: none !important;
  -webkit-perspective: none !important;
}

@media (min-width: 2048px) {
  .faq-footer-height {
    height: 5.5rem;
  }
  .faq-content-height {
    height: calc(100% - 5.5rem);
  }
  .player-height {
    height: 54vh !important;
  }
}
