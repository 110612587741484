.more_icons{
    height: 3rem;
    width: auto;

    border-radius: 0.5rem;
   
    position: absolute;
    content: '';
    top: -10px;
    left: 3.75rem;
    z-index: 10;
    transition: all .3s ease;
}

@media screen and (max-width: 1024px) {
    .more_icons{
        height: 4rem;
width: auto;
border-radius: 0.5rem;
position: absolute;
content: '';
top: -100px;
left: -0.45rem;
z-index: 10;
transition: all .3s ease;
}


 .more_icons > button {
 margin: 0 !important;}
}

.more_icons > button:not(:last-child) {
    margin-right: 20px;
}
