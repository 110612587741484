:root {
  --reach-combobox: 1;
}

.pink[data-reach-combobox] {
  /* width: 300px; */
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid transparent;
}

.pink[data-reach-combobox]:focus-within {
  border: 1px solid #ea596f;
  -webkit-box-shadow: 0 0 2px #ea596f;
  box-shadow: 0 0 2px #ea596f;
}

.pink [data-reach-combobox-input] {
  outline: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: .25rem .5rem .25rem .5rem;
  font-size: 100%;
  border: 1px solid #ccc;
  margin: 0;
}

/* [data-reach-combobox-input] {
  width: 100%;
  border: 6px solid #ccc;

} */

.pink [data-reach-combobox-popover] {
  border: 1px solid #ccc;
  border-top: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  top: -1px;
}

.pink [data-reach-combobox-option] {
  padding: .25rem 1rem;
  text-align: start;
}

.pink hr {
  margin: 0 1rem .5rem;
  display: block;
  height: 1px;
  border: none;
  background: #ccc;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

[data-reach-combobox-popover] {
  border: solid 1px hsla(0, 0%, 0%, 0.25);
  background: hsla(0, 100%, 100%, 0.99);
  font-size: 85%;
}

[data-reach-combobox-list] {
  list-style: none;
  margin: 0;
  padding: 0;
  user-select: none;
}

[data-reach-combobox-option] {
  cursor: pointer;
  margin: 0;
  padding: 0.25rem 0.5rem;
}

[data-reach-combobox-option][aria-selected="true"] {
  background: hsl(211, 10%, 95%);
}

[data-reach-combobox-option]:hover {
  background: hsl(211, 10%, 92%);
}

[data-reach-combobox-option][aria-selected="true"]:hover {
  background: hsl(211, 10%, 90%);
}

[data-suggested-value] {
  font-weight: bold;
}