.affiliate-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 415px;
}

.affiliate-popup p {
    margin-bottom: 10px;
}

.affiliate-popup button {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.affiliate-popup button:hover {
    background-color: #0056b3;
}

.affiliate-popup .code {
    font-family: monospace;
    background-color: #f7f7f7;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.affiliate-popup .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    color: #999;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.affiliate-popup .close-button:hover {
    color: #555;
}

.affiliate-popup .copy-button {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.affiliate-popup .copy-button:hover {
    background-color: #0056b3;
}