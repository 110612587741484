.clamp-3-lines, .clamp-5-lines, .clamp-7-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 3; */
  -webkit-box-pack: end;
  /* padding-top: 1em; */
}

/* change dislay */
.clamp-3-lines p, .clamp-5-lines p, .clamp-7-lines p {
  display: inline !important; 
}

.clamp-3-lines *, .clamp-5-lines *, .clamp-7-lines * {
  display: inline-block;
}

.clamp-3-lines {
  -webkit-line-clamp: 3;
}

.clamp-5-lines {
  -webkit-line-clamp: 5;
}

.clamp-7-lines {
  -webkit-line-clamp: 7;
}
