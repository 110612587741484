
.gridParent {
  /* background-color: blue; */
 display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
    max-height: 40rem; 
}

@media screen and (max-width: 1023px) {
  .gridParent {
    /* background-color: red; */
    display: grid;
grid-template-columns: 1fr;
grid-template-rows: repeat(20, 1fr);
grid-column-gap: 0px;
grid-row-gap: 0px;
  }
}

.stepsContainer {
  grid-area: 1 / 1 / 2 / 3;
  z-index: 10;
}

@media screen and (max-width: 1023px) {
  .stepsContainer {
    grid-area: 1 / 1 / 3 / 5;
  }
}

.formContainer {
  grid-area: 1 / 2 / 2 / 21;
}

@media screen and (max-width: 1023px) {
  .formContainer {
    grid-area: 2 / 1 / 21 / 5;
  }
}

/* .contentHeight {
  height: 100vh;
  max-height: 20rem;
}

@media screen and (max-width: 1023px) {
  .contentHeight {
    height: 100%;
  }
} */

.main-section {
  height: calc(100% - 3rem - 2.5rem);
}

.main-section-without-footer {
  height: calc(100% - 2.5rem);
}
.logo-section {
  height: 2.5rem;
}

.footer {
  height: 3rem;
}

@media screen and (min-width: 767px) {
  .main-section {
    height: calc(100% - 3rem - 3rem);
  }
  
  .main-section-without-footer {
    height: calc(100% - 3rem);
  }
  .logo-section {
    height: 3rem;
  }

}