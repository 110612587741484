.mentalHealthList li:before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: black; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.cardMentalHealth {
  top: 2px;
}

#mhblue {
  text-color: "#476ab1" !important;
}

#mhpink {
  text-color: "#de549b" !important;
}

.w-full.max-w-sm {
  height: 650px;
}

@media (min-width: 2000px) {
  .w-full.max-w-sm {
    height: 750px;
  }
}
