  
 
 /* @media screen and (min-width: 768px) {
    .Aboutme-height {
        height: 16rem;
    }
    .Calendar-height {
        height: calc(100% - 16rem);
    }
  } */

  @media screen and (min-width: 1024px) {
    .Aboutme-height {
        height: 14rem;
    }
    .Calendar-height {
      height: calc(100% - 14rem);
    }
  }
  

  .course-item { transition: all .2s ease-in-out; }
.course-item:hover { transform: scale(1.05); }

