.Title-height {
  height: 4rem;
}
.Content-height {
  height: calc(100% - 4rem);
}

@media screen and (min-width: 1024px) {
  .Title-height {
    height: 5rem;
  }
  .Content-height {
    height: calc(100% - 5rem);
  }
}

/* .overflow-x-auto::-webkit-scrollbar, .overflow-y-auto::-webkit-scrollbar, .overflow-x-scroll::-webkit-scrollbar, .overflow-y-auto::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

.overflow-x-auto::-webkit-scrollbar, .overflow-y-auto::-webkit-scrollbar, .overflow-x-scroll::-webkit-scrollbar, .overflow-y-scroll::-webkit-scrollbar {
  display: block;
}

.overflow-x-auto::-webkit-scrollbar-track, .overflow-y-auto::-webkit-scrollbar-track, .overflow-x-scroll::-webkit-track, .overflow-y-scroll::-webkit-track {
  background-color: #F7F7F7 !important;
  border-radius: 2px !important;
}

.overflow-x-auto::-webkit-scrollbar-thumb, .overflow-y-auto::-webkit-scrollbar-thumb, .overflow-x-scroll::-webkit-scrollbar-thumb, .overflow-y-scroll::-webkit-scrollbar-thumb {
  border-radius: 6px !important;
  background-color: #CDCCCC !important; 
} */
