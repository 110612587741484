/* #root {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
     background: linear-gradient(#e66465, #9198e5); 
  } */

/* #root>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .app>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */

.row {
  flex-direction: row !important;
}

.row > * {
  margin: 5px;
}

:root {
  --swipeHeaderHeight: 6rem;
  --swipeFooterHeight: 4rem;
}
.swipe {
  position: absolute;
}
.cardHeader {
  height: var(--swipeFooterHeight);
}
.cardFooter {
  height: var(--swipeFooterHeight);
}
.cardContainer {
  width: 80%;
  height: calc(100% - var(--swipeHeaderHeight) - var(--swipeFooterHeight));
  /* width: calc(100% - var(--swipeHeaderHeight) - var(--swipeFooterHeight));  */
  /* max-width: 300px !important; */
  /* height: 300px;  */
}

.card {
  position: relative;
  background-color: #fff;
  /* width: 80vw;
    max-width: 260px;
    height: 300px; */
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.cardContent {
  width: 100%;
  height: 100%;
}

.quizCardHeight {
  height: 28rem;
}

/* .card h3 {
    position: absolute;
    bottom: 0;
    margin: 10px;
    color: #fff;
  } */

/* .infoText {
    width: 100%;
    height: 28px;
    justify-content: center;
    display: flex;
    color: #fff;
    animation-name: popup;
    animation-duration: 800ms;
  }
   */
/* .buttons {
    margin: 2px;
    display: flex;
  }
   */
/* .buttons button {
    flex-shrink: 0;
    padding: 1px;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 12px;
    background-color: #9198e5;
    transition: 200ms;
    margin: 0 10px;
    font-weight: bolder;
    width: 50px;
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.10);
  } */

/* .buttons button:hover {
    transform: scale(1.05);
  }
   */
@keyframes popup {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(1.1, 1.1);
  }
  30% {
    transform: scale(0.9, 0.9);
  }
  50% {
    transform: scale(1, 1);
  }
  57% {
    transform: scale(1, 1);
  }
  64% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.react-card-flip {
  width: 100%;
  height: 100%;
}

.ageTitle {
  white-space: "pre-line";
}

.ageImage {
  transition: filter 0.3s ease-in-out, transform 0.4s ease-in-out;
  -webkit-transition: filter 0.3s ease-in-out, transform 0.4s ease-in-out;
  filter: brightness(80%);
  background-color: black;
  position: relative;
}

.ageImage:hover {
  transform: scale(1.1);
  filter: brightness(100%);
}

.ageCard {
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  height: fit-content;
  width: 20rem;
}

/* .Demo__some-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
}

.Demo__some-network__share-count {
  display: inline-flex;
  justify-content: center;
  white-space: nowrap;
  overflow: visible;
  width: 0;
  margin-top: 3px;
  font-size: 12px;
}

.Demo__some-network__share-button {
  cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}

.Demo__some-network__custom-icon {
  width:32px;
  height:32px;
} */
/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait */

@media only screen and (min-device-width: 640px) {
  .cardContainer {
    width: 20rem;
    max-width: 22rem !important;
    max-height: 45rem !important;
    /* height: 300px;  */
  }
}

@media (max-width: 360px) {
  .quizCardHeight {
    height: 26rem;
  }
}
