
.hidden_fees{
    color: blue;
    padding: 2rem;
    margin-top: 10px;
    width: 100%;
    border: 2px solid red;
    overflow: hidden;
    display: block;
    transition: 0.4s max-height;
}

@media (min-width:480px) {
    .transactionHeader{
        width: 15rem; 
    }

}

.heightBoxHeader {
    height: 3rem;
}

@media (min-width:1024px) {
    .heightBoxContent{
        height: calc(100% - 3rem);
    }
}
