@media only screen and (max-width: 770px) {
  .pr-12p {
    padding-right: 12%;
  }
}

.profile-section-a {
  min-height: 36rem;
}
@media only screen and (min-width: 768px) {
  .profile-section-a {
    max-height: 48rem;
  }
}

.profile_input {
  border: 0;
}

video,
button {
  display: block;
}
